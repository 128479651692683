import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { Flex } from 'rebass'

import UserContext from 'context/user/UserContext'
import Layout from 'components/layout'
import SEO from 'components/seo'
import LottieShape from 'components/animations/LottieShape'
import TeamAnimation from 'components/animations/abstract/TeamAnimation'
import SignInUI from 'components/SignInUI'
import { CREATE_MEETING } from 'services/graphql'

const NewMeeting = (props) => {
  const ERROR_MESSAGE = 'An error has ocurred.'

  const { t } = useTranslation('newMeeting')
  const { user, isAuthInitialized } = useContext(UserContext)
  const [
    createMeeting,
    { data: createMeetingData, error: createMeetingError },
  ] = useMutation(CREATE_MEETING)
  const siteTitle = props.data.site.siteMetadata.title

  const showUnauthorizedUserFragment = isAuthInitialized && !user

  const notifyError = () => toast.error(ERROR_MESSAGE)

  const UnauthorizedUserFragment = (
    <SignInUI heading={t('unauthorizedHeading')} signInSuccessUrl="" />
  )

  const ErrorFragment = <h1>{t('genericError')}</h1>

  const AuthorizedUserFragment = (
    <>
      <h1>
        {t('greeting', {
          name: user && user.displayName ? user.displayName : null,
        })}
      </h1>
      <Flex flexWrap="wrap-reverse" alignContent="center" alignItems="center">
        <LottieShape animationData={TeamAnimation} size={325} />
        <h2>{t('creatingMeeting')}</h2>
      </Flex>
    </>
  )

  useEffect(() => {
    const startCreateMeeting = async () => {
      await createMeeting()
    }

    if (user && user.uid) {
      startCreateMeeting(user.uid)
    }
  }, [user, createMeeting])

  // redirect the user to new 3Dmeet meeting if valid, otherwise notify error using toast
  useEffect(() => {
    if (
      createMeetingData &&
      createMeetingData.createMeeting &&
      typeof window !== 'undefined'
    ) {
      window.location.assign(createMeetingData.createMeeting.hostLink)
    }
  }, [createMeetingData])

  useEffect(() => {
    if (createMeetingError) {
      notifyError()
    }
  }, [createMeetingError])

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title={t('title')} />
      {!isAuthInitialized ? <h3>{t('loading')}</h3> : null}
      {showUnauthorizedUserFragment && !createMeetingError
        ? UnauthorizedUserFragment
        : null}
      {isAuthInitialized && user && !createMeetingError
        ? AuthorizedUserFragment
        : null}
      {createMeetingError ? ErrorFragment : null}
    </Layout>
  )
}

export default NewMeeting

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
